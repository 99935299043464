import { memo, useContext, useMemo } from 'react';

import { Fade } from '@mui/material';
import { format, isPast } from 'date-fns';
import { useTranslation } from 'react-i18next';

import EventElement from './components/EventElement';
import { HeaderEvents, ContentEvents, EventsList, ListMarker, MyTitle } from './styled';
import { EventsBlockProps } from './types';
import { FORMAT_BY } from 'constants/format';
import { AppContext } from 'context';
import { filterBySameDay } from 'services/filter';
import { formatDateRange, formatDateTime } from 'services/format';
import { addLanguageToUrl } from 'services/createUrl';
import useDateFnsLocale from 'services/useDateFnsLocale';
import theme from 'theme';

const EventsBlock = memo(({ selectedDate, events }: EventsBlockProps) => {
    const dateFnsLocale = useDateFnsLocale();
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const {
        datetimeFormat,
        layouts: {
            CALENDAR: { eventFields },
        },
        directUrl,
    } = useContext(AppContext);
    const formatDate = format(selectedDate, FORMAT_BY.DayOfWeekWithFullDate, {
        locale: dateFnsLocale,
    });
    const eventsBySelectedDate = useMemo(() => filterBySameDay(events, selectedDate), [events, selectedDate]);
    const resultEvents = useMemo(
        () =>
            eventsBySelectedDate.map((event) => {
                const eventIsPast = event.ends !== null && isPast(new Date(event.ends));
                let markColor = theme.palette.indicator.closed;
                if (eventIsPast) {
                    markColor = theme.palette.indicator.past;
                } else if (event.ticket_status !== 'SOLD_OUT') {
                    markColor = theme.palette.indicator.open;
                }

                return {
                    id: event.id,
                    title: event.name,
                    isPast: eventIsPast,
                    date: formatDateRange(
                        [new Date(event.starts), event.ends ? new Date(event.ends) : null],
                        datetimeFormat,
                        dateFnsLocale
                    ),
                    startDate: formatDateTime(new Date(event.starts), datetimeFormat, dateFnsLocale),
                    endDate: formatDateTime(new Date(event.ends), datetimeFormat, dateFnsLocale),
                    location: event.location.city,
                    venue: event.location.venue,
                    ticketStatus: event.ticket_status,
                    markColor,
                    links: event.links,
                };
            }),
        [eventsBySelectedDate]
    );

    return (
        <div>
            <HeaderEvents>
                <MyTitle>{formatDate}</MyTitle>
            </HeaderEvents>
            <ContentEvents>
                <EventsList>
                    {resultEvents.map((item) => (
                        <Fade in key={item.id}>
                            <ListMarker sx={{ color: item.markColor }}>
                                <EventElement
                                    isPast={item.isPast}
                                    layout={eventFields}
                                    title={item.title}
                                    date={item.date}
                                    startDate={item.startDate}
                                    endDate={item.endDate}
                                    eventId={item.id}
                                    location={item.location}
                                    venue={item.venue}
                                    ticketStatus={item.ticketStatus}
                                    registrationLink={
                                        directUrl
                                            ? addLanguageToUrl(item.links.direct_registration, language)
                                            : addLanguageToUrl(item.links.event_page, language)
                                    }
                                    eventPageLink={addLanguageToUrl(item.links.event_page, language)}
                                />
                            </ListMarker>
                        </Fade>
                    ))}
                </EventsList>
            </ContentEvents>
        </div>
    );
});

export default EventsBlock;

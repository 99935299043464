import { memo } from 'react';

import { Fade } from '@mui/material';

import { Mark, TypographySelected, CellDiv } from './styled';
import { CellProps } from './types';

const Cell = memo(({ formatDay, isMark, markClass, bgColor, textColor, day, onDateClick }: CellProps) => {
    return (
        <CellDiv className='date' onClick={onDateClick(day)}>
            <TypographySelected as='span' sx={{ color: textColor, backgroundColor: bgColor }}>
                {formatDay}
            </TypographySelected>
            {isMark ? (
                <Fade in>
                    <Mark className={markClass} />
                </Fade>
            ) : (
                <Mark />
            )}
        </CellDiv>
    );
});

export default Cell;

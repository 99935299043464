import { memo } from 'react';

import { isPast } from 'date-fns';
import { useTranslation } from 'react-i18next';

import {
    TextSpan,
    MyPaper,
    TextDiv,
    TextBlock,
    CalendarIcon,
    LocationIcon,
    LocationCity,
    RegisterButton,
    HoverTitle,
    SoldOutButton,
} from './styled';
import { ListElementProps } from './types';
import { formatDateRange, formatDateTime } from 'services/format';
import useDateFnsLocale from 'services/useDateFnsLocale';

const ListElement = memo((props: ListElementProps) => {
    const {
        title,
        startDate,
        endDate,
        location,
        venue,
        datetimeFormat,
        ticketStatus,
        eventPageLink,
        registrationLink,
        layout,
    } = props;
    const { t } = useTranslation();
    const dateFnsLocale = useDateFnsLocale();
    const isParagraph = true;
    const eventIsPast = endDate !== null && isPast(new Date(endDate));

    const dateString = formatDateRange(
        [new Date(startDate), endDate !== null ? new Date(endDate) : null],
        datetimeFormat,
        dateFnsLocale
    );
    const startDateString = formatDateTime(new Date(startDate), datetimeFormat, dateFnsLocale);
    const endDateString = formatDateTime(new Date(endDate), datetimeFormat, dateFnsLocale);

    return (
        <MyPaper variant='outlined'>
            <TextDiv>
                {layout.includes('NAME') && (
                    <HoverTitle
                        data-testid='title'
                        variant='h6'
                        fontWeight='bold'
                        paragraph={isParagraph}
                        href={eventPageLink}
                        className='hover-title'
                    >
                        {title}
                    </HoverTitle>
                )}
                {(layout.includes('START_DATE') || layout.includes('END_DATE') || layout.includes('LOCATION')) && (
                    <TextBlock variant='body2'>
                        {layout.includes('START_DATE') && layout.includes('END_DATE') && (
                            <>
                                <CalendarIcon color='primary' />
                                <TextSpan data-testid='date'>{dateString}</TextSpan>
                            </>
                        )}
                        {layout.includes('START_DATE') && !layout.includes('END_DATE') && (
                            <>
                                <CalendarIcon color='primary' />
                                <TextSpan data-testid='date'>{startDateString}</TextSpan>
                            </>
                        )}
                        {!layout.includes('START_DATE') && layout.includes('END_DATE') && (
                            <>
                                <CalendarIcon color='primary' />
                                <TextSpan data-testid='date'>{endDateString}</TextSpan>
                            </>
                        )}
                        {layout.includes('LOCATION') && location && (
                            <>
                                <LocationCity color='primary' />
                                <TextSpan data-testid='location'>{location}</TextSpan>
                            </>
                        )}
                        {layout.includes('VENUE') && venue && (
                            <>
                                <LocationIcon color='primary' />
                                <TextSpan data-testid='location'>{venue}</TextSpan>
                            </>
                        )}
                    </TextBlock>
                )}
                {eventIsPast && <TextBlock>{t('message.event_has_ended')}</TextBlock>}
            </TextDiv>

            {layout.includes('REGISTRATION_BUTTON') && (
                <div>
                    {['AVAILABLE', 'WAITLIST'].includes(ticketStatus) && (
                        <RegisterButton variant='contained' disabled={eventIsPast} href={registrationLink}>
                            {t('form.register')}
                        </RegisterButton>
                    )}
                    {ticketStatus === 'SOLD_OUT' && (
                        <SoldOutButton variant='contained' disabled>
                            {t('form.sold_out')}
                        </SoldOutButton>
                    )}
                    {ticketStatus === 'NOT_OPEN' && (
                        <SoldOutButton variant='contained' disabled>
                            {t('form.not_open')}
                        </SoldOutButton>
                    )}
                </div>
            )}
        </MyPaper>
    );
});

export default ListElement;

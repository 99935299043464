// var fs = require('fs');
// var locales = {};

// // // Read in the libs from this directory and add them as exports
// // // This way you can just reference

// var files = fs.readdirSync('./lang');

// files.forEach(function (file) {
// 	if (file.indexOf('.json') > -1 && file !== 'index.js') {
// 		locales[file] = require('./' + file);
// 	}
// });

// export { locales };

import de from './lang/de.json';
import en from './lang/en.json';
import fi from './lang/fi.json';
import fr from './lang/fr.json';
import ru from './lang/ru.json';
import sv from './lang/sv.json';
import zh from './lang/zh.json';

// let resources = {};
// ['en', 'fi'].forEach((language) => {
// 	resources[language] = {
// 		translation: locales[language]
// 	};
// });

const resources = {
    en: { translation: en },
    fi: { translation: fi },
    sv: { translation: sv },
    de: { translation: de },
    fr: { translation: fr },
    ru: { translation: ru },
    zh: { translation: zh },

    dev: { translation: en },
};

export { en, fi, resources, sv, de, fr, ru, zh };

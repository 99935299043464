import { styled, Typography } from '@mui/material';

export const CellDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column',
    maxWidth: '14.2%',
    height: 60,
    flex: '1 0 14.2%',
    [theme.breakpoints.down(440)]: {
        fontSize: '80%',
        height: 40,
    },
}));

export const TypographySelected = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 36,
    height: 36,
    borderRadius: '50%',
    cursor: 'pointer',
    [theme.breakpoints.down(440)]: {
        width: 30,
        height: 30,
    },
}));

export const Mark = styled('div')(({ theme }) => ({
    height: 8,
    width: 8,
    marginTop: 4,
    borderRadius: '50%',
    '&.closed': {
        backgroundColor: theme.palette.indicator.closed,
    },
    '&.open': {
        backgroundColor: theme.palette.indicator.open,
    },
    '&.past': {
        backgroundColor: theme.palette.indicator.past,
    },
    [theme.breakpoints.down(440)]: {
        marginTop: 2,
        height: 6,
        width: 6,
    },
}));

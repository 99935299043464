import { ChangeEvent, FC, memo, useCallback, useContext, useState } from 'react';

import SwapVertSharpIcon from '@mui/icons-material/SwapVertSharp';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DateRangePicker from './components/DateRangePicker';
import { BottomFilterDiv, MyRadioGroup, MyFormControlLabel, MyHiddenRadio } from './styled';
import { FILTER_BY } from 'constants/filter';
import { FORMAT_BY } from 'constants/format';
import { SORT_DIRECTION } from 'constants/sort';
import { AppContext } from 'context';
import { formatDateRange } from 'services/format';
import useDateFnsLocale from 'services/useDateFnsLocale';

const BottomFilterForm: FC = memo(() => {
    const { t } = useTranslation();
    const dateFnsLocale = useDateFnsLocale();
    const { direction, setDirection, filterType, setFilterType } = useContext(AppContext);
    const [open, setOpen] = useState(false);

    const sortHandleChange = useCallback(() => {
        setDirection((prev) =>
            prev === SORT_DIRECTION.Ascending ? SORT_DIRECTION.Descending : SORT_DIRECTION.Ascending
        );
    }, [direction]);

    const filterHandleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (!open) {
                setFilterType({ ...filterType, filterBy: e.target.value });
            }
        },
        [open, filterType]
    );

    const openDateRageWindow = () => {
        setOpen(true);
    };

    return (
        <BottomFilterDiv>
            <DateRangePicker open={open} setOpen={setOpen} />
            <MyRadioGroup
                value={filterType.filterBy}
                defaultValue={FILTER_BY.UpcomingEvents}
                onChange={filterHandleChange}
            >
                <MyFormControlLabel
                    value={FILTER_BY.UpcomingEvents}
                    control={<MyHiddenRadio color='primary' />}
                    data-testid='upcoming'
                    label={
                        <Typography color={filterType.filterBy === FILTER_BY.UpcomingEvents ? 'primary' : 'black'}>
                            {t('form.upcoming_events')}
                        </Typography>
                    }
                />
                <MyFormControlLabel
                    value={FILTER_BY.AllEvents}
                    control={<MyHiddenRadio color='primary' />}
                    data-testid='all'
                    label={
                        <Typography color={filterType.filterBy === FILTER_BY.AllEvents ? 'primary' : 'black'}>
                            {t('form.all_events')}
                        </Typography>
                    }
                />
                <MyFormControlLabel
                    value={FILTER_BY.DateRange}
                    onClick={openDateRageWindow}
                    control={<MyHiddenRadio color='primary' />}
                    data-testid='dateRange'
                    label={
                        <Typography
                            color={filterType.filterBy === FILTER_BY.DateRange ? 'primary' : 'black'}
                            borderBottom={'1px dashed #828282'}
                        >
                            {t('form.date_range')}
                            {filterType.dateRange
                                ? ': ' + formatDateRange(filterType.dateRange, FORMAT_BY.FullDate, dateFnsLocale)
                                : ''}
                        </Typography>
                    }
                />
            </MyRadioGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        value={direction}
                        onChange={sortHandleChange}
                        color='primary'
                        icon={<SwapVertSharpIcon />}
                        checkedIcon={<SwapVertSharpIcon />}
                        size='small'
                    />
                }
                label={`${t('form.sort_by_date')}: ${t('form.' + direction.toLowerCase() + 'ending')}`}
                labelPlacement='start'
            />
        </BottomFilterDiv>
    );
});

export default BottomFilterForm;

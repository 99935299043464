import { memo, useMemo } from 'react';

import { startOfWeek, format, addDays } from 'date-fns';

import { DayOfWeekBlock, TextBlock } from './styled';
import { DaysProps } from './types';
import { FORMAT_BY } from 'constants/format';
import useDateFnsLocale from 'services/useDateFnsLocale';

const DaysOfWeek = memo(({ currentDate }: DaysProps) => {
    const startDate = startOfWeek(currentDate, { weekStartsOn: 1 });
    const dateFnsLocale = useDateFnsLocale();

    const daysOfWeek = useMemo(
        () =>
            [...new Array(7)].map((current, index) =>
                format(addDays(startDate, index), FORMAT_BY.Week, {
                    locale: dateFnsLocale,
                })
            ),
        [startDate]
    );

    return (
        <>
            {daysOfWeek.map((item) => (
                <DayOfWeekBlock key={item}>
                    <TextBlock>{item}</TextBlock>
                </DayOfWeekBlock>
            ))}
        </>
    );
});

export default DaysOfWeek;

export default (uri: string) => {
    // To test custom theme/localization in localhost, serve the file from public folder
    const request = new Request(uri, {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
    });
    return fetch(request).then((response) => {
        if (response.ok) {
            try {
                return response.json();
            } catch (e) {
                return 'Invalid json format';
            }
        } else {
            return 'Unable to fetch custom ' + uri;
        }
    });
};

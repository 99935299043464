import { ThemeOptions } from '@mui/material';

declare module '@mui/material/styles/createPalette' {
    interface Palette {
        indicator: {
            open: string;
            closed: string;
            past: string;
        };
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        indicator: {
            open: string;
            closed: string;
            past: string;
        };
    }
}

// https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
declare module '@mui/material/styles' {
    interface TypographyVariants {
        title: React.CSSProperties;
        description: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        title?: React.CSSProperties;
        description?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        title: true;
        description: true;
    }
}

const theme: ThemeOptions = {
    typography: {
        fontFamily: 'Arial, Roboto, Helvetica, sans-serif',
    },
    palette: {
        primary: {
            main: '#7749F8',
        },
        indicator: {
            open: '#27ae60',
            closed: '#EB5757',
            past: 'transparent',
        },
        background: {
            default: null,
        },
    },
};

export const themeEmbed: ThemeOptions = {
    ...theme,
    typography: {
        title: {
            ...theme.typography,
            fontSize: '2.5rem',
        },
        description: {
            ...theme.typography,
        },
        body1: {
            fontSize: null,
            margin: null,
            color: null,
            fontWeight: null,
            fontFamily: null,
            letterSpacing: null,
            lineHeight: null,
            '& #eventilla-calendar': {
                margin: 0,
                color: '#000000de',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: 1.5,
                letterSpacing: '0.00938em',
                '& p': {
                    margin: 0,
                },
            },
        },
    },
    components: {
        ...theme.components,
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    title: 'h1',
                    description: 'div',
                },
            },
        },
        MuiDialog: {
            defaultProps: {
                disablePortal: true,
            },
        },
        MuiMenu: {
            defaultProps: {
                disablePortal: true,
            },
        },
        MuiPopover: {
            defaultProps: {
                disablePortal: true,
            },
        },
        MuiModal: {
            defaultProps: {
                disablePortal: true,
            },
        },

        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& fieldset': {
                        top: 0,
                    },
                    '& legend': {
                        display: 'none',
                    },
                },
            },
        },
    },
};

export default theme;

import { ChangeEvent, memo, useCallback, useContext } from 'react';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import GridOnIcon from '@mui/icons-material/GridOn';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import { FormControlLabel, Radio, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { MyRadioGroup } from './styled';
import { CONTENT_VIEW } from 'constants/content';
import { AppContext } from 'context';

const ContentViewForm = memo(() => {
    const { t } = useTranslation();
    const { setContentView, contentView, contentViews } = useContext(AppContext);

    const contentViewHandleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const newView = e.target.value;
        if (newView === CONTENT_VIEW.List || newView === CONTENT_VIEW.Calendar || newView === CONTENT_VIEW.Grid) {
            setContentView(newView);
        }
    }, []);

    // Only one view available -> don't show navigation
    if (contentViews.length <= 1) {
        return null;
    }

    return (
        <MyRadioGroup value={contentView} defaultValue={contentViews[0]} onChange={contentViewHandleChange}>
            {contentViews.includes(CONTENT_VIEW.List) && (
                <FormControlLabel
                    value={CONTENT_VIEW.List}
                    data-testid='list'
                    control={
                        <Radio icon={<ListAltOutlinedIcon />} color='primary' checkedIcon={<ListAltOutlinedIcon />} />
                    }
                    label={
                        <Typography
                            component='span'
                            color={contentView === CONTENT_VIEW.List ? 'primary.main' : 'black'}
                        >
                            {t('form.list_view')}
                        </Typography>
                    }
                />
            )}
            {contentViews.includes(CONTENT_VIEW.Grid) && (
                <FormControlLabel
                    value={CONTENT_VIEW.Grid}
                    control={<Radio icon={<GridOnIcon />} color='primary' checkedIcon={<GridOnIcon />} />}
                    data-testid='grid'
                    label={
                        <Typography color={contentView === CONTENT_VIEW.Grid ? 'primary.main' : 'black'}>
                            {t('form.grid_view')}
                        </Typography>
                    }
                />
            )}
            {contentViews.includes(CONTENT_VIEW.Calendar) && (
                <FormControlLabel
                    value={CONTENT_VIEW.Calendar}
                    control={<Radio icon={<CalendarTodayIcon />} color='primary' checkedIcon={<CalendarTodayIcon />} />}
                    data-testid='calendar'
                    label={
                        <Typography color={contentView === CONTENT_VIEW.Calendar ? 'primary.main' : 'black'}>
                            {t('form.calendar_view')}
                        </Typography>
                    }
                />
            )}
        </MyRadioGroup>
    );
});

export default ContentViewForm;

import DateRangeSharpIcon from '@mui/icons-material/DateRangeSharp';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PersonIcon from '@mui/icons-material/Person';
import { lighten, Link, styled } from '@mui/material';

export const DescriptionSpan = styled('span')(({ theme }) => ({
    marginLeft: theme.spacing(1),
}));

export const DescriptionBlock = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down(440)]: {
        fontSize: '60%',
    },
}));

export const CalendarIcon = styled(DateRangeSharpIcon)({
    width: 15,
    height: 15,
});

export const LocationIcon = styled(LocationOnSharpIcon)({
    width: 15,
    height: 15,
});

export const LocationCity = styled(LocationCityIcon)({
    width: 15,
    height: 15,
});

export const SlotIcon = styled(PersonIcon)({
    width: 15,
    height: 15,
});

export const HoverText = styled(Link)(({ theme }) => ({
    [theme.breakpoints.down(440)]: {
        fontSize: '80%',
    },
}));

export const EventBlock = styled('div')(({ theme }) => ({
    color: 'black',
    marginBottom: theme.spacing(3),
    '&:hover .hover-title': {
        color: theme.palette.primary,
        textDecoration: 'underline',
        textDecorationColor: lighten(theme.palette.primary.main, 0.7),
    },
}));

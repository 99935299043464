import DateRangeSharpIcon from '@mui/icons-material/DateRangeSharp';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { styled, lighten, Link, Paper, Typography, Button } from '@mui/material';

export const TextDiv = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-evenly',
});

export const HoverTitle = styled(Link)(({ theme }) => ({
    color: '#333',
    '&:hover': {
        color: theme.palette.primary.main,
    },
    [theme.breakpoints.down(468)]: {
        fontSize: '80%',
    },
}));

export const SoldOutButton = styled(Button)(({ theme }) => ({
    '& .MuiButton-label': {
        color: 'red',
        [theme.breakpoints.down(468)]: {
            fontSize: '70%',
        },
    },
}));

export const RegisterButton = styled(Button)(({ theme }) => ({
    '& .MuiButton-label': {
        [theme.breakpoints.down(468)]: {
            fontSize: '70%',
        },
    },
}));

export const MyPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(1),
    height: 'auto',
    [theme.breakpoints.down(468)]: {
        justifyContent: 'space-around',
        flexDirection: 'column',
        padding: theme.spacing(2),
    },
    '&:hover .hover-title': {
        textDecoration: 'underline',
        textDecorationColor: lighten(theme.palette.primary.main, 0.7),
    },
}));

export const TextSpan = styled('span')(({ theme }) => ({
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(468)]: {
        fontSize: '70%',
    },
}));

export const CalendarIcon = styled(DateRangeSharpIcon)({
    width: 15,
    height: 15,
});

export const LocationIcon = styled(LocationOnSharpIcon)({
    width: 15,
    height: 15,
});

export const LocationCity = styled(LocationCityIcon)({
    width: 15,
    height: 15,
});

export const TextBlock = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
});

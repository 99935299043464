import { FC, useContext, useState, useCallback, memo, useEffect } from 'react';

import { LocalizationProvider, MobileDateRangePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DateRangeProps } from './types';
import { FILTER_BY } from 'constants/filter';
import { AppContext } from 'context';
import useDateFnsLocale from 'services/useDateFnsLocale';

const DateRangePicker: FC<DateRangeProps> = memo(({ open, setOpen }: DateRangeProps) => {
    const { t } = useTranslation();
    const dateFnsLocale = useDateFnsLocale();
    const {
        setFilterType,
        filterType: { dateRange },
    } = useContext(AppContext);
    const [range, setRange] = useState<[null | Date, null | Date]>([
        dateRange ? dateRange[0] : null,
        dateRange ? dateRange[1] : null,
    ]);

    useEffect(() => {
        if (range[0] !== dateRange[0] || range[1] !== dateRange[1]) {
            setRange(dateRange);
        }
    }, [dateRange]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        if (range[0] || range[1]) {
            setFilterType((filterType) => ({ ...filterType, filterBy: FILTER_BY.DateRange, dateRange: range }));
        }
        setOpen(false);
    };

    const handleDateRange = useCallback((dateRange: any) => {
        setRange(dateRange);
    }, []);

    return (
        <Dialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
            <DialogTitle id='customized-dialog-title'>{t('form.date_range')}</DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={dateFnsLocale}>
                    <MobileDateRangePicker
                        startText={t('form.start_date')}
                        endText={t('form.end_date')}
                        mask='____-__-__'
                        inputFormat='yyyy-MM-dd'
                        value={range}
                        clearText={t('form.clear')}
                        okText={t('form.ok')}
                        cancelText={t('form.cancel')}
                        todayText={t('form.today')}
                        toolbarTitle={t('form.select') + ' ' + t('form.date_range')}
                        onChange={handleDateRange}
                        renderInput={(startProps, endProps) => (
                            <>
                                <TextField {...startProps} variant='standard' />
                                <Box sx={{ mx: 2 }}> — </Box>
                                <TextField {...endProps} variant='standard' />
                            </>
                        )}
                    />
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave} variant='contained' color='primary'>
                    {t('form.save_changes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default DateRangePicker;

import { styled } from '@mui/material';

export const HeaderEvents = styled('div')({
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    boxSizing: 'border-box',
    boxShadow: '10px 24px 1px -24px',
});

export const MyTitle = styled('h2')(({ theme }) => ({
    [theme.breakpoints.down(440)]: {
        fontSize: '100%',
    },
}));

export const ContentEvents = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    marginRight: theme.spacing(2),
    boxSizing: 'border-box',
    overflowY: 'scroll',
    scrollbarColor: '#e0e0e0 white',
    scrollbarWidth: 'thin',
    maxHeight: 350,
    '&::-webkit-scrollbar': {
        background: 'white',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#e0e0e0',
        border: '5px solid transparent',
        backgroundClip: 'content-box',
    },
}));

export const EventsList = styled('ul')({
    margin: 0,
    padding: 0,
});

export const ListMarker = styled('li', {
    overridesResolver: (props, styles) => {
        return styles;
    },
})(({ theme }) => ({
    // color: (props: ListMarkProps) => props.color,
    fontSize: '150%',
    [theme.breakpoints.down(440)]: {
        fontSize: '100%',
    },
}));

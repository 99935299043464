import de from 'date-fns/locale/de';
import enUS from 'date-fns/locale/en-US';
import fi from 'date-fns/locale/fi';
import fr from 'date-fns/locale/fr';
import ru from 'date-fns/locale/ru';
import sv from 'date-fns/locale/sv';
import zhCN from 'date-fns/locale/zh-CN';
import { useTranslation } from 'react-i18next';

const useDateFnsLocale = () => {
    const { i18n } = useTranslation();

    switch (i18n.language) {
        case 'en':
            return enUS;
        case 'fi':
            return fi;
        case 'sv':
            return sv;
        case 'de':
            return de;
        case 'ru':
            return ru;
        case 'fr':
            return fr;
        case 'zh':
            return zhCN;
        default:
            return enUS;
    }
};

export default useDateFnsLocale;

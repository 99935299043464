import a1Url from './a1Url';

let headerOptions = {
    'Content-Type': 'application/json',
};
// Use X Auth Token if set and not production
if (process.env.NODE_ENV !== 'production' && typeof process.env.REACT_APP_X_AUTH_TOKEN !== 'undefined') {
    headerOptions['X-Auth-Token'] = process.env.REACT_APP_X_AUTH_TOKEN;
}
const headers = new Headers(headerOptions);

export const get = (query) => {
    const request = requestCreator(query, 'GET');
    return fetch(request).then((response) => response.json());
};

export const post = (query, body) => {
    const request = requestCreator(query, 'POST', body);
    return fetch(request).then((response) => response.json());
};

export const put = (query, body) => {
    const request = requestCreator(query, 'PUT', body);
    return fetch(request).then((response) => response.json());
};

export const del = (query) => {
    const request = requestCreator(query, 'DELETE');
    return fetch(request).then((response) => response.json());
};

export const requestCreator = (uri, method, body, embedUrl = false) =>
    new Request(a1Url(uri, embedUrl), {
        method,
        headers,
        body: body !== null ? JSON.stringify(body) : null,
    });

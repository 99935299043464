import { memo, useCallback, useMemo } from 'react';

import { Box, Grid } from '@mui/material';
import { startOfYear, format, addMonths } from 'date-fns';

import { MonthBlock } from './styled';
import { MonthsProps } from './types';
import { FORMAT_BY } from 'constants/format';
import useDateFnsLocale from 'services/useDateFnsLocale';

const Months = memo(({ currentDate, setCalendarByMonth, setCurrentDate, setSelectedDate }: MonthsProps) => {
    const dateFnsLocale = useDateFnsLocale();
    const startMonths = startOfYear(currentDate);
    const selectMonth = useCallback(
        (date: Date) => () => {
            setCurrentDate(date);
            setSelectedDate(date);
            setCalendarByMonth(false);
        },
        []
    );
    const monthsOfYear = useMemo(
        () =>
            [...new Array(12)].map((current, index) => {
                const currentMonth = addMonths(startMonths, index);
                const currentMonthFormat = format(addMonths(startMonths, index), FORMAT_BY.Month, {
                    locale: dateFnsLocale,
                });

                return { currentMonth, currentMonthFormat };
            }),
        [startMonths]
    );

    return (
        <Grid>
            <Box display='flex' flexWrap='wrap' textAlign='center' data-testid='months'>
                {monthsOfYear.map((item) => (
                    <MonthBlock key={item.currentMonth.toString()} onClick={selectMonth(item.currentMonth)}>
                        {item.currentMonthFormat}
                    </MonthBlock>
                ))}
            </Box>
        </Grid>
    );
});

export default Months;

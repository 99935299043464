import { format, isSameDay } from 'date-fns';

export const formatDateTime = (date: Date, formatBy: string, locale: Locale) => {
    return format(date, formatBy, { locale });
};

const isDateTimeFormat = (formatBy: string) => {
    const formattedFormatBy = formatBy.replace(/[:.]/g, '?').toLowerCase();
    return formattedFormatBy === 'dd?mm?yyyy kk?mm' || formattedFormatBy === 'dd?mm?yyyy hh?mm';
};

const isISO8601DateTimeFormat = (formatBy: string) => {
    const formattedFormatBy = formatBy.replace(/[.:]/g, '?').toLowerCase();
    return formattedFormatBy === 'dd mmm yyyy hh?mm' || formattedFormatBy === 'dd mmm yyyy kk?mm';
};

export const formatDateRange = (date: [Date | null, Date | null], formatBy: string, locale: Locale): string => {
    const from = date[0] ? format(date[0], formatBy, { locale }) : '';
    if (date[1]) {
        const to = date[1] ? format(date[1], formatBy, { locale }) : '';
        if (isSameDay(date[0], date[1])) {
            if (isDateTimeFormat(formatBy)) {
                const [fromDate, fromTime] = from.split(' ');
                const splitTo = to.split(' ');
                return `${fromDate} ${fromTime}-${splitTo[1]}`;
            } else if (isISO8601DateTimeFormat(formatBy)) {
                const splitTo = to.split(' ');
                return `${from}-${splitTo[3]}`;
            } else {
                return `${from} — ${to}`;
            }
        } else {
            return `${from} — ${to}`;
        }
    } else {
        return from;
    }
};

export const diffDates = (startDate: Date, endDate: Date) => {
    return Math.ceil((endDate.getTime() - startDate.getTime()) / (60 * 60 * 24 * 1000));
};

export const formatDateByFullDateTime = (date: string, formatBy: string) => {
    if (date === null) {
        return null;
    }
    return format(Date.parse(date), formatBy);
};

export const eventillaDate = (date: Date) => {
    // eslint-disable-next-line quotes
    return format(date, "yyyy-MM-dd'T'HH:mm:ssXXX");
};
// 2021-06-08T00:00:00+03:00

import { Dispatch, SetStateAction } from 'react';

import { ThemeOptions } from '@mui/material';

import { CONTENT_VIEW } from 'constants/content';
import { SortDirection } from 'constants/sort';
import {
    CalendarEventField,
    CalendarLayoutFilters,
    CalendarView,
    GridEventField,
    ListEventField,
} from 'interfaces/ICalendar';
import { IEvent, Tag } from 'interfaces/IEvent';

export enum Filter {
    FilterBy = 'filterBy',
    DateRange = 'dateRange',
    Locations = 'locations',
    Tags = 'tags',
    TextField = 'textField',
}

export type FilterType = {
    [Filter.FilterBy]: string;
    [Filter.DateRange]: [Date | null, Date | null];
    [Filter.Locations]: Set<string>;
    // [Filter.Tags]: Set<Tag>;
    [Filter.Tags]: string[];
    [Filter.TextField]: string;
};

export type Layouts = {
    [CONTENT_VIEW.List]: {
        filters: CalendarLayoutFilters;
        eventFields: ListEventField[];
    };
    [CONTENT_VIEW.Grid]: {
        filters: CalendarLayoutFilters;
        eventFields: GridEventField[];
        logoHeight: string | number;
    };
    [CONTENT_VIEW.Calendar]: {
        filters: CalendarLayoutFilters;
        eventFields: CalendarEventField[];
    };
};

export type AppContextState = {
    contentView: CalendarView;
    contentViews: CalendarView[];
    changedLanguage: string;
    setLanguage: Dispatch<SetStateAction<any>>;
    events: IEvent[];
    setContentView: Dispatch<SetStateAction<CalendarView>>;
    direction: SortDirection;
    setDirection: Dispatch<SetStateAction<SortDirection>>;
    filterType: FilterType;
    setFilterType: Dispatch<SetStateAction<FilterType>>;
    setEventPage: Dispatch<SetStateAction<number>>;
    availableLocations: Set<string>;
    foundTags: Set<Tag>; // Tags found through events/ api calls
    datetimeFormat: string;
    eventsDefaultImage: string | null;
    loading: boolean;
    error: any;
    translations: {
        language: string;
        description: string;
        title: string;
        html_title: string;
    }[];
    layouts: Layouts;
    organizationUri: null | string;
    organizationDefaultCalendarLogo: string;
    theme: ThemeOptions;
    hasMoreEvents: boolean;
    directUrl: boolean;
};

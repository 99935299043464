import { memo, useCallback } from 'react';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { IconButton, Grid } from '@mui/material';
import { format, addMonths, subMonths, addYears, subYears } from 'date-fns';

import { HeaderCalendar, EmptyCheckbox, DateTitle, MyFormControlLabel } from './styled';
import { HeaderProps } from './types';
import { FORMAT_BY } from 'constants/format';
import useDateFnsLocale from 'services/useDateFnsLocale';

const Header = memo(
    ({ setCurrentDate, setSelectedDate, currentDate, calendarByMonth, setCalendarByMonth }: HeaderProps) => {
        const dateFnsLocale = useDateFnsLocale();
        const currentMontAndYear = format(currentDate, FORMAT_BY.MonthAndYear, {
            locale: dateFnsLocale,
        });
        const currentYear = format(currentDate, FORMAT_BY.Year);

        const nextBehaviour = useCallback(() => {
            if (calendarByMonth) {
                setCurrentDate(addYears(currentDate, 1));
            } else {
                const newDate = addMonths(currentDate, 1);
                setSelectedDate(newDate);
                setCurrentDate(newDate);
            }
        }, [currentDate, calendarByMonth]);

        const pervBehaviour = useCallback(() => {
            if (calendarByMonth) {
                setCurrentDate(subYears(currentDate, 1));
            } else {
                setSelectedDate(subMonths(currentDate, 1));
                setCurrentDate(subMonths(currentDate, 1));
            }
        }, [currentDate, calendarByMonth]);

        const handleChange = useCallback(() => {
            setCalendarByMonth(!calendarByMonth);
        }, [calendarByMonth]);

        return (
            <HeaderCalendar container>
                <Grid item xs={2}>
                    <IconButton data-testid='prevButton' onClick={pervBehaviour}>
                        <ArrowLeftIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={8}>
                    <MyFormControlLabel
                        value='top'
                        control={<EmptyCheckbox value={calendarByMonth} onChange={handleChange} />}
                        label={
                            <DateTitle data-testid='title'>
                                {calendarByMonth ? currentYear : currentMontAndYear}
                            </DateTitle>
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <IconButton data-testid='nextButton' onClick={nextBehaviour}>
                        <ArrowRightIcon />
                    </IconButton>
                </Grid>
            </HeaderCalendar>
        );
    }
);

export default Header;

import { ChangeEvent, memo, useCallback, useContext, useState } from 'react';

import { Button, Checkbox, Grid, ListItemText, MenuItem } from '@mui/material';
import capitalize from 'lodash/capitalize';
import isArray from 'lodash/isArray';
import { useTranslation } from 'react-i18next';

import { FormDiv, StyledSelect, StyledTextField } from './styled';
import { AppContext } from 'context';

const MainFilterForm = memo(() => {
    const { t } = useTranslation();
    const { foundTags, availableLocations, setFilterType, filterType, contentView, layouts } = useContext(AppContext);

    const tagOptions: {
        value: string;
        label: string;
    }[] = [];
    Array.from(foundTags).forEach((tag) => {
        const _name = (tag.display_name !== null && tag.display_name !== '' ? tag.display_name : tag.key).toLowerCase();
        if (!tagOptions.some((tagOption) => tagOption.value === _name)) {
            tagOptions.push({
                value: _name,
                label: tag.display_name !== null && tag.display_name !== '' ? tag.display_name : tag.key,
            });
        }
    });
    tagOptions.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        return 0;
    });

    // const [selectedTag, setSelectedTag] = useState<string[]>(filterType.tags ?? []);
    const [selectedLocation, setSelectedLocation] = useState<string[]>(Array.from(filterType.locations) ?? []);
    const [textField, setTextField] = useState(filterType.textField ?? '');

    // Layout visibility for filters
    const showTagsFilter = layouts[contentView].filters.includes('TAGS');
    const showLocationsFilter = layouts[contentView].filters.includes('LOCATION');
    const showSearchFilter = layouts[contentView].filters.includes('SEARCH');

    const handleChangeTags = useCallback(
        (e: any) => {
            const tagNames: string[] = e.target.value;
            if (tagNames.includes('')) {
                setFilterType({ ...filterType, tags: [] });
            } else {
                setFilterType({ ...filterType, tags: tagNames });
            }
        },
        [filterType]
    );

    const handleChangeLocations = useCallback(
        (e: any) => {
            if (e.target.value.includes('')) {
                setSelectedLocation([]);
                setFilterType({ ...filterType, locations: new Set() });
            } else {
                setSelectedLocation(e.target.value);
                setFilterType({ ...filterType, locations: new Set(e.target.value) });
            }
        },
        [filterType]
    );

    const handleTextField = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setTextField(e.target.value);
    }, []);

    const handleOnSubmit = useCallback(
        (e: ChangeEvent<HTMLFormElement>) => {
            e.preventDefault();
            setFilterType({ ...filterType, textField });
        },
        [filterType, textField]
    );

    const handleOnReset = useCallback(() => {
        setFilterType({ ...filterType, locations: new Set(), tags: [], textField: '', dateRange: [null, null] });
        setSelectedLocation([]);
        setTextField('');
    }, [filterType]);

    if (!showTagsFilter && !showLocationsFilter && !showSearchFilter) {
        return null;
    }

    const sortedLocations = Array.from(availableLocations).sort();

    return (
        <FormDiv>
            <form aria-label='form' onSubmit={handleOnSubmit} onReset={handleOnReset}>
                <Grid container spacing={3}>
                    {showTagsFilter && (
                        <Grid item xs={6} md={2}>
                            <StyledSelect
                                variant='outlined'
                                size='small'
                                value={filterType.tags}
                                multiple
                                MenuProps={{
                                    sx: { maxHeight: 400 },
                                }}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (isArray(selected)) {
                                        if (selected.length === 0) {
                                            return <em>{t('form.all_tags')}</em>;
                                        }
                                        let isFirst = true;
                                        const renderString = tagOptions.map((tagOption) => {
                                            if (selected.includes(tagOption.value)) {
                                                const optionString = `${isFirst ? '' : ', '}${tagOption.label}`;
                                                isFirst = false;
                                                return optionString;
                                            } else {
                                                return '';
                                            }
                                        });
                                        return renderString;
                                    }
                                    return null;
                                }}
                                onChange={handleChangeTags}
                            >
                                <MenuItem value=''>{t('form.all_tags')}</MenuItem>
                                {tagOptions.map((tagOption) => {
                                    const isChecked = filterType.tags.includes(tagOption.value);
                                    return (
                                        <MenuItem key={tagOption.value} value={tagOption.value} selected={isChecked}>
                                            <Checkbox checked={isChecked} />
                                            <ListItemText primary={tagOption.label} />
                                        </MenuItem>
                                    );
                                })}
                            </StyledSelect>
                        </Grid>
                    )}
                    {showLocationsFilter && (
                        <Grid item xs={6} md={2}>
                            <StyledSelect
                                variant='outlined'
                                size='small'
                                onChange={handleChangeLocations}
                                multiple
                                value={selectedLocation}
                                data-testid='locationSelect'
                                displayEmpty
                                MenuProps={{
                                    sx: { maxHeight: 400 },
                                }}
                                renderValue={(selected) => {
                                    if (isArray(selected)) {
                                        if (selected.length === 0) {
                                            return <em>{t('form.any_city')}</em>;
                                        }
                                        return selected.join(', ');
                                    }
                                    return null;
                                }}
                            >
                                <MenuItem value=''>{t('form.any_city')} </MenuItem>
                                {sortedLocations.map((location) => (
                                    <MenuItem
                                        key={location}
                                        value={location}
                                        selected={filterType.locations.has(location)}
                                    >
                                        <Checkbox checked={selectedLocation.indexOf(location) > -1} />
                                        <ListItemText primary={capitalize(location)} />
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </Grid>
                    )}
                    {showSearchFilter && (
                        <Grid item xs={12} md={3}>
                            <StyledTextField
                                value={textField}
                                onChange={handleTextField}
                                type='text'
                                size='small'
                                data-testid='textfield'
                                placeholder={t('form.search_for_event')}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} md={5}>
                        {showSearchFilter && (
                            <Button sx={{ mr: 1.8 }} data-testid='submitButton' variant='contained' type='submit'>
                                {t('form.search')}
                            </Button>
                        )}
                        <Button data-testid='resetButton' variant='contained' color='inherit' type='reset'>
                            {t('form.reset')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormDiv>
    );
});

export default MainFilterForm;

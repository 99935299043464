const a1Url = (query, embedUrl = false) => {
    let restUrl;
    let envUrl = (embedUrl ? process.env.REACT_APP_EMBED_BASE_URL : process.env.REACT_APP_BASE_URL) + '/rest/a1';
    if (envUrl.substring(0, 4) === 'http') {
        restUrl = envUrl;
    } else {
        restUrl = window.location.protocol + '//' + window.location.hostname + envUrl;
    }
    const url = restUrl + (query.substr(0, 1) === '/' ? query : '/' + query);
    return url;
};

export default a1Url;

import { Select, styled, TextField } from '@mui/material';

export const FormDiv = styled('div')(({ theme }) => ({
    backgroundColor: '#f2f2f2',
    height: '10%',
    padding: theme.spacing(4),
    boxSizing: 'border-box',
    marginBottom: theme.spacing(3),
}));

export const StyledSelect = styled(Select)({
    width: '100%',
    backgroundColor: 'white',
    color: 'gray',
});

export const StyledTextField = styled(TextField)({
    width: '100%',
    backgroundColor: 'white',
    color: 'gray',
});

// export const MarginButton = styled(Button)`
// 	margin-right: theme.spacing(1.8)}

import { useEffect, useContext } from 'react';

import { Container, Fade } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Header from './components/Header';
import useTitle from './services/useTitle';
import CalendarView from 'components/CalendarView';
import GridView from 'components/GridView';
import ListView from 'components/ListView';
import { CONTENT_VIEW } from 'constants/content';
import { useApp } from 'context';
import { CalendarView as CalendarViewType } from 'interfaces/ICalendar';
import { AppContext } from 'context';

const App = ({ contentView }: { contentView: CalendarViewType }) => {
    const { i18n } = useTranslation();
    const { translations } = useApp();
    const { setLanguage } = useContext(AppContext);
    // Set head title from calendar translations
    const newTitle = translations.find(
        (translation) => translation.language.toLowerCase() === i18n.language
    )?.html_title;
    useTitle(typeof newTitle === 'string' && newTitle !== '' ? newTitle : document.title);

    useEffect(() => {
        const handle = (e: any) => {
            i18n.changeLanguage(e.target.lang);
            document.documentElement.lang = e.target.lang;
            setLanguage(e.target.lang);
        };
        const changeLanguageButtons = document
            .getElementById('change-language')
            ?.getElementsByClassName('language-button');
        const changeLanguageMobileButtons = document
            .getElementById('languageMenu')
            ?.getElementsByClassName('dropdown-item');

        // Desktop
        if (changeLanguageButtons) {
            Array.prototype.forEach.call(changeLanguageButtons, (button) => {
                button.addEventListener('click', handle);
            });
        }
        // Mobile
        if (changeLanguageMobileButtons) {
            Array.prototype.forEach.call(changeLanguageMobileButtons, (button) => {
                button.addEventListener('click', handle);
            });
        }

        return () => {
            if (changeLanguageButtons) {
                Array.prototype.forEach.call(changeLanguageButtons, (button) => {
                    button.removeEventListener('click', handle);
                });
            }
            if (changeLanguageMobileButtons) {
                Array.prototype.forEach.call(changeLanguageMobileButtons, (button) => {
                    button.removeEventListener('click', handle);
                });
            }
        };
    }, []);

    return (
        <div>
            <Container>
                <Fade in>
                    <div>
                        <Header />
                    </div>
                </Fade>
                {contentView === CONTENT_VIEW.List && (
                    <Fade in>
                        <div>
                            <ListView />
                        </div>
                    </Fade>
                )}
                {contentView === CONTENT_VIEW.Grid && (
                    <Fade in>
                        <div>
                            <GridView />
                        </div>
                    </Fade>
                )}
                {contentView === CONTENT_VIEW.Calendar && (
                    <Fade in>
                        <div>
                            <CalendarView />
                        </div>
                    </Fade>
                )}
            </Container>
        </div>
    );
};

export default App;

export default function createExtraScopePlugin(...extra) {
    const scopes = extra.map((scope) => `${scope.trim()} `);

    return (element) => {
        if (element.type !== 'rule') {
            return;
        }

        if (element.root?.type === '@keyframes') {
            return;
        }
        let a = [];
        let b = [];

        if (!element.parent || (element.props.length === 1 && element.value.charCodeAt(0) !== 58) || !element.length) {
            element.props = element.props
                .map((prop) =>
                    scopes.map((scope) => {
                        if (prop.startsWith('html') || prop.startsWith('body')) {
                            b.push(prop);
                            return prop;
                        }
                        a.push(scope + prop);
                        return scope + prop;
                    })
                )
                .reduce((scopesArray, scope) => scopesArray.concat(scope), []);
        }
    };
}

import i18n, { TFunction } from 'i18next';
import merge from 'lodash/merge';
import { initReactI18next } from 'react-i18next';

import { resources } from './localization';

// import Backend from 'i18next-xhr-backend';

export const defaultLanguage = 'en';
type Config = { lng?: string; customLocalizations?: unknown };
type I18nInitFunc = (config?: Config, callback?: (error: unknown, t: TFunction) => void) => void;

export const i18nInit: I18nInitFunc = (config = { lng: defaultLanguage, customLocalizations: {} }, callback) => {
    i18n
    // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // // learn more: https://github.com/i18next/i18next-xhr-backend
    // .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
        .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
        .init(
            {
                resources: merge({}, resources, config?.customLocalizations),
                lng: config.lng,
                fallbackLng: process.env.NODE_ENV === 'production' ? defaultLanguage : 'dev', // Default 'dev' -> enables saveMissing

                // Backend
                // preload: ['en', 'fi'],
                // preload: [config.lng],
                // backend: {
                // 	loadPath: 'shared/locales/{{lng}}/{{ns}}.json?v=' + new Date().getTime()
                // }

                interpolation: {
                    escapeValue: false, // not needed for react as it escapes by default
                },
                // debug: process.env.NODE_ENV !== 'production',
            },
            (error, t) => {
                if (callback) {
                    callback(error, t);
                }

                if (error) {
                    console.error('something went wrong loading', error);
                    return false;
                }
                return true;
            }
        );
};

export default i18n;

import { useEffect, useState, useReducer, useCallback } from 'react';

import isEqual from 'lodash/isEqual';

import queryFromObject from './queryFromObject';
import { requestCreator } from './request';
import { isEmpty } from './variableTypeChecks';
import { SortDirection } from 'constants/sort';

type State = {
    result: any;
    error: any;
    loading: boolean;
};

type Action = {
    type: string;
    data?: any;
};

// useA1 initial state
const initialValue: State = { result: null, error: null, loading: false };

// Cached responses. Each key is an operation (ex. events/123) and values are responses
const responseCache: any = {};

export const DEFAULT_LIMIT = 250;

// useReducer function
const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'FETCH':
            return { ...initialValue, result: state.result, loading: true };
        case 'SUCCESS':
            return { ...initialValue, result: action.data, loading: false };
        case 'CACHED':
            return { ...initialValue, result: action.data, loading: false };
        case 'FAIL':
            return { ...initialValue, result: state.result, error: action.data, loading: false };
        case 'CLEAR_ERROR':
            return { ...initialValue, error: null };
        case 'SKIP':
            return { ...initialValue, result: state.result };
        case 'RESET':
        default:
            return initialValue;
    }
};

/**
 * A1 rest api hook
 * @param {string} operation rest url ex. 'users/me'
 * @param {object} options options
 * @param {boolean} options.skip don't fetch until false, default false. Tries to preserve previous result
 * @param {boolean} options.cache if already fetched once, do not fetch automatically again
 * @param {string} options.method HTTP request method (GET, POST, PUT, DEL), default 'GET'
 * @param {object} options.body HTTP request body
 * @param {object} options.query Url parameters
 * @param {function} options.callback On successfull fetch, run this callback
 * @param {boolean} options.pagination Use pagination in hook
 */

export type QueryParams = {
    limit?: number;
    tags?: string[];
    location?: string[];
    search?: string;
    sort_by?: string;
    direction?: SortDirection;
    from?: string;
    to?: string;
    language?: string;
    organization_id?: number;
    organization_uuid?: string;
    suborganizations?: boolean;
};

type Options = {
    skip?: boolean;
    cache?: boolean;
    method?: string;
    body?: any;
    query?: QueryParams;
    pagination?: boolean;
    embedUrl?: boolean;
};

const useA1 = (
    operation: string,
    {
        skip = false,
        cache = true,
        method = 'GET',
        body = null,
        query = {},
        pagination = false,
        embedUrl = false,
    }: Options = {}
) => {
    // Pagination states
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(query?.limit ?? (pagination ? DEFAULT_LIMIT : 0));
    const [sortBy, setSortBy] = useState('starts');
    const [sortByDirection, setSortByDirection] = useState<SortDirection>('');

    const [refreshIndex, setRefreshIndex] = useState(0);

    let stringQuery = '';
    if (pagination) {
        // Default query params for pagination functionality
        let newQueryParams: {
            offset: number;
            limit: number;
            sort_by?: string;
            direction?: SortDirection;
        } = { offset: page * limit, limit };

        // Add sort by if one is set
        if (sortBy !== '') {
            newQueryParams.sort_by = sortBy;
            newQueryParams.direction = sortByDirection;
        }

        // Hook query option takes priority
        newQueryParams = { ...newQueryParams, ...query };
        stringQuery = queryFromObject(newQueryParams);
    } else {
        stringQuery = queryFromObject(query);
    }
    const cacheKey = operation + stringQuery;
    /**
     * Helper for setting current operation cache value
     * @param {any} value
     */
    const setCache = (value: any) => (responseCache[cacheKey] = value);

    /**
     * Helper for getting current operation cache value
     * @param {any} value
     */
    const getCache = (): any => responseCache[cacheKey];

    /**
     * Clear cache and fetch again
     */
    const refresh = useCallback(() => {
        setCache(null);
        setRefreshIndex((refreshIndex) => refreshIndex + 1);
    }, []);

    // Loading should be set to true by default, when hook starts with a fetch
    const [state, dispatch] = useReducer(reducer, initialValue, (initialValue) => {
        if (!skip) {
            if (cache && getCache()) {
                return { ...initialValue, loading: false, data: getCache() };
            } else {
                return { ...initialValue, loading: true };
            }
        }
        return initialValue;
    });

    /**
     * Reset everything to initial state
     */
    const clear = useCallback(() => {
        setRefreshIndex(0);
        setCache(null);
        dispatch({ type: 'RESET' });
    }, []);

    /**
     * Set error to null
     */
    const clearError = useCallback(() => {
        dispatch({ type: 'CLEAR_ERROR' });
    }, []);

    /**
     * Pagination limit changing. Calculate new page index based on reac-table usePagination docs:
     * "As a result of a pageSize change, a new state.pageIndex is also calculated.
     *  It is calculated via Math.floor(currentTopRowIndex / newPageSize)"
     * @param {number} limit Amount of rows to show on page
     */
    const handleLimitChange = useCallback(
        (limit) => {
            setLimit(limit);
            if (state.result?.offset) {
                setPage(Math.floor(state.result?.offset / limit));
            } else {
                setPage(0);
            }
        },
        [state.result?.offset]
    );

    /**
     * Create new sort query parameters from react-table sort data
     * @param {string} sortBy Field name to sort by
     */
    const handleSortByChange = useCallback(
        (newSortBy) => {
            if (isEmpty(newSortBy) && sortBy !== '') {
                setSortBy('');
            } else if (!isEmpty(newSortBy)) {
                setSortBy(newSortBy[0].id);
                setSortByDirection(newSortBy[0].desc ? 'DESC' : 'ASC');
            }
        },
        [sortBy]
    );

    /**
     * Run fetch when operation or refreshIndex changes.
     */
    useEffect(() => {
        let cancelRequest = false; // This is set to true in the useEffect cleanup function
        // If cache not in use or the cache for current operation is empty
        if (!cache || !getCache()) {
            if (skip) {
                dispatch({ type: 'SKIP' });
            } else {
                dispatch({ type: 'FETCH' });

                fetch(requestCreator(operation + stringQuery, method, body, embedUrl))
                    .then((response) => {
                        if (!response.ok) {
                            throw response.json();
                        } else if (!cancelRequest) {
                            return response.json();
                        } else {
                            return null;
                        }
                    })
                    .then((json) => {
                        if (!cancelRequest) {
                            if (cache) {
                                setCache(json);
                            }
                            dispatch({ type: 'SUCCESS', data: json });
                        }
                    })
                    .catch((error) => {
                        if (!cancelRequest) {
                            if (error?.then) {
                                error.then((json: string) => {
                                    dispatch({ type: 'FAIL', data: json });
                                });
                            } else {
                                dispatch({
                                    type: 'FAIL',
                                    data: {
                                        status_message:
                                            error?.message ?? 'Error occurred when attempting to fetch resource.',
                                    },
                                });
                            }
                        }
                    });
            }
        } else {
            // Use cached value
            const cachedValue = getCache();
            if (!state.result || !isEqual(state.result, cachedValue)) {
                dispatch({ type: 'CACHED', data: getCache() });
            }
        }

        return () => {
            cancelRequest = true;
        };
    }, [operation, refreshIndex, stringQuery, skip]);

    // @eventilla/components Table component pagination props
    const paginationProps = {
        onPageChange: setPage,
        onShowAmountChange: handleLimitChange,
        onSortByChange: handleSortByChange,

        totalRows: state.result?.total ?? 0,
        totalPages: -1, // server side (-1)
    };

    return {
        ...state,
        paginationProps,
        setPage,
        setLimit,
        refresh,
        clearError,
        clear,
    };
};

export default useA1;

import { styled, FormControlLabel, Radio, RadioGroup } from '@mui/material';

export const BottomFilterDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
}));

export const MyRadioGroup = styled(RadioGroup)({
    display: 'flex',
    flexDirection: 'row',
});

export const MyFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    marginLeft: theme.spacing(0.1),
}));

export const MyHiddenRadio = styled(Radio)({
    display: 'none',
});

import React from 'react';

import ReactDOM from 'react-dom';

import AppProvider from './context';
import reportWebVitals from './reportWebVitals';
import AppInit from 'AppInit';

// const rootElement = document.getElementById('CALENDAR_APP_ID' in window ? window.CALENDAR_APP_ID : 'root');
const rootElement = document.getElementById('eventilla-calendar');

ReactDOM.render(
    <React.StrictMode>
        <AppProvider>
            <AppInit />
        </AppProvider>
    </React.StrictMode>,
    rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

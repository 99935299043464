import { useLayoutEffect, useState } from 'react';

import debounce from 'lodash/debounce';

function useWindowSize(callback: () => void) {
    const debouncedCallback = debounce(() => callback(), 10);
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
            debouncedCallback();
        }

        window.addEventListener('resize', updateSize);
        updateSize();

        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
}

export default useWindowSize;

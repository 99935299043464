import { styled } from '@mui/material';

export const MonthBlock = styled('div')(({ theme }) => ({
    maxWidth: '25%',
    flex: '1 0 25%',
    textAlign: 'center',
    fontWeight: 'bold',
    margin: theme.spacing(5, 0),
    cursor: 'pointer',
    [theme.breakpoints.down(440)]: {
        fontSize: '60%',
    },
    [theme.breakpoints.between(681, 880)]: {
        fontSize: '80%',
    },
}));

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { Container, CssBaseline, ThemeProvider } from '@mui/material';

import App from 'App';
import { useApp } from 'context';
import stylisPluginExtraScope from 'stylisPluginExtraScope';

const AppInit = (): JSX.Element => {
    const { loading, error, theme, contentView } = useApp();

    // Create emotion cache
    const cache = createCache({
        key: 'css',
        stylisPlugins: [stylisPluginExtraScope('#eventilla-calendar')],
    });

    if (error) {
        console.error(error?.status_message ?? error);
        return <Container>404</Container>;
    }

    if (loading) {
        return null;
    }
    return (
        <CacheProvider value={cache}>
            <ThemeProvider theme={theme}>
                <CssBaseline />

                <App contentView={contentView} />
            </ThemeProvider>
        </CacheProvider>
    );
};

export default AppInit;

import { forwardRef, memo, useContext } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import GridElement from '../GridElement';
import { GridRowProps } from './types';
import { AppContext } from 'context';
import noPhoto from './no-photo.jpg';
import { addLanguageToUrl } from 'services/createUrl';
import { IEvent } from 'interfaces/IEvent';

const GridRow = memo(
    forwardRef<HTMLDivElement, GridRowProps>(
        ({ columnCount, index, events, blockSize }: GridRowProps, ref): JSX.Element => {
            const {
                t,
                i18n: { language },
            } = useTranslation();
            const {
                datetimeFormat,
                eventsDefaultImage,
                organizationDefaultCalendarLogo,
                layouts: {
                    GRID: { eventFields, logoHeight },
                },
                directUrl,
            } = useContext(AppContext);

            const getEventImage = (event: IEvent) => {
                return (
                    event.images?.calendar_logo ||
                    eventsDefaultImage ||
                    organizationDefaultCalendarLogo ||
                    event.images?.event_header_thumb ||
                    `${process.env.PUBLIC_URL}${noPhoto}`
                );
            };

            const grids = [...new Array(columnCount)].map((_, i) => i + columnCount * index);

            return (
                <Grid container columnSpacing={3} ref={ref}>
                    {grids.map(
                        (index) =>
                            events[index] && (
                                <Grid item xs={blockSize} key={events[index].id}>
                                    <GridElement
                                        layout={eventFields}
                                        title={events[index].name}
                                        startDate={events[index].starts}
                                        endDate={events[index].ends}
                                        datetimeFormat={datetimeFormat}
                                        location={events[index].location.city}
                                        venue={events[index].location.venue}
                                        logo={getEventImage(events[index])}
                                        shortDescription={events[index].short_description}
                                        logoHeight={logoHeight}
                                        ticketStatus={events[index].ticket_status}
                                        eventPageLink={addLanguageToUrl(events[index].links.event_page, language)}
                                        registrationLink={
                                            directUrl
                                                ? addLanguageToUrl(events[index].links.direct_registration, language)
                                                : addLanguageToUrl(events[index].links.event_page, language)
                                        }
                                    />
                                </Grid>
                            )
                    )}
                </Grid>
            );
        }
    )
);

export default GridRow;

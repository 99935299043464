import { memo, useState, useContext, useEffect } from 'react';

import { Grid, Paper } from '@mui/material';
import { endOfMonth, endOfWeek, startOfMonth } from 'date-fns';
import startOfWeek from 'date-fns/startOfWeek';

import CalendarBlock from '../CalendarBlock';
import EventsBlock from '../EventsBlock';
import { GridWithBorder } from './styled';
import { AppContext } from 'context';

const hideGhostDates = true;

const CalendarView = memo(() => {
    const { events, setFilterType } = useContext(AppContext);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [currentDate, setCurrentDate] = useState(new Date());
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(currentDate);

    useEffect(() => {
        const startDate = hideGhostDates ? monthStart : startOfWeek(monthStart);
        const endDate = hideGhostDates ? monthEnd : endOfWeek(monthEnd);
        setFilterType((filterType) => ({
            ...filterType,
            dateRange: [startDate, endDate],
        }));

        return () => {
            setFilterType((filterType) => ({
                ...filterType,
                dateRange: [null, null],
            }));
        };
    }, [monthStart.toString(), monthEnd.toString()]);

    return (
        <Paper variant='outlined' data-testid='calendarView'>
            <Grid container>
                <GridWithBorder item sm={12} md={6} data-testid='calendar'>
                    <CalendarBlock
                        events={events}
                        currentDate={monthStart}
                        setCurrentDate={setCurrentDate}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        hideGhostDates={hideGhostDates}
                    />
                </GridWithBorder>
                <Grid item sm={12} md={6} data-testid='events'>
                    <EventsBlock selectedDate={selectedDate} events={events} />
                </Grid>
            </Grid>
        </Paper>
    );
});

export default CalendarView;

import DateRangeSharpIcon from '@mui/icons-material/DateRangeSharp';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import LocationCityIcon from '@mui/icons-material/LocationCity';

import { Button, Card, CardContent, lighten, Link, styled, Typography } from '@mui/material';

export const HoverText = styled(Link)(({ theme }) => ({
    color: '#333',
    '&:hover': {
        color: theme.palette.primary.main,
    },
}));

export const MyCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    alignSelf: 'stretch',
    width: '100%',

    '&:hover .hover-title': {
        textDecoration: 'underline',
        textDecorationColor: lighten(theme.palette.primary.main, 0.7),
    },
}));

export const StyledCardContent = styled(CardContent)({
    display: 'flex',
    flexFlow: 'column',
});

export const MyDescription = styled(Typography)(({ theme }) => ({
    lineClamp: 3,
    marginTop: theme.spacing(2),
    wordBreak: 'break-word',
}));

export const TextSpan = styled('span')(({ theme }) => ({
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
}));

export const MyTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

export const SoldOutButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(3),
    alignSelf: 'baseline',
    '& .MuiButton-label': {
        color: 'red',
    },
}));

export const RegisterButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(3),
    alignSelf: 'baseline',
}));

export const CalendarIcon = styled(DateRangeSharpIcon)({
    width: 15,
    height: 15,
});

export const LocationIcon = styled(LocationOnSharpIcon)({
    width: 15,
    height: 15,
});

export const LocationCity = styled(LocationCityIcon)({
    width: 15,
    height: 15,
});

export const TextBlock = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
});

import { memo } from 'react';

import { CardMedia, Link } from '@mui/material';
import { isPast } from 'date-fns';
import { useTranslation } from 'react-i18next';

import {
    TextSpan,
    SoldOutButton,
    RegisterButton,
    MyCard,
    CalendarIcon,
    LocationIcon,
    LocationCity,
    TextBlock,
    HoverText,
    StyledCardContent,
    MyDescription,
} from './styled';
import { GridElementProps } from './types';
import { formatDateRange, formatDateTime } from 'services/format';
import useDateFnsLocale from 'services/useDateFnsLocale';

const GridElement = memo(
    ({
        title,
        startDate,
        endDate,
        location,
        venue,
        logo,
        logoHeight,
        ticketStatus,
        eventPageLink,
        registrationLink,
        layout,
        datetimeFormat,
        shortDescription,
    }: GridElementProps) => {
        const { t } = useTranslation();
        const dateFnsLocale = useDateFnsLocale();
        const isParagraph = true;
        const eventIsPast = endDate !== null && isPast(new Date(endDate));

        const dateString = formatDateRange(
            [new Date(startDate), endDate !== null ? new Date(endDate) : null],
            datetimeFormat,
            dateFnsLocale
        );
        const startDateString = formatDateTime(new Date(startDate), datetimeFormat, dateFnsLocale);
        const endDateString = formatDateTime(new Date(endDate), datetimeFormat, dateFnsLocale);

        return (
            <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                <MyCard>
                    {layout.includes('LOGO') && (
                        <Link href={eventPageLink}>
                            <CardMedia
                                data-testid='logo'
                                component='img'
                                width='100%'
                                height={logoHeight}
                                sx={{ height: logoHeight }}
                                image={logo}
                            />
                        </Link>
                    )}
                    <StyledCardContent
                        sx={{
                            height: `calc(100% - ${logoHeight}px)`,
                        }}
                    >
                        {layout.includes('NAME') && (
                            <HoverText
                                data-testid='title'
                                variant='h6'
                                fontWeight='bold'
                                paragraph={isParagraph}
                                href={eventPageLink}
                                className='hover-title'
                            >
                                {title}
                            </HoverText>
                        )}

                        {(layout.includes('START_DATE') || layout.includes('END_DATE')) && (
                            <TextBlock variant='body2' paragraph={isParagraph}>
                                {layout.includes('START_DATE') && layout.includes('END_DATE') && (
                                    <>
                                        <CalendarIcon color='primary' />
                                        <TextSpan data-testid='date'>{dateString}</TextSpan>
                                    </>
                                )}
                                {layout.includes('START_DATE') && !layout.includes('END_DATE') && (
                                    <>
                                        <CalendarIcon color='primary' />
                                        <TextSpan data-testid='date'>{startDateString}</TextSpan>
                                    </>
                                )}
                                {!layout.includes('START_DATE') && layout.includes('END_DATE') && (
                                    <>
                                        <CalendarIcon color='primary' />
                                        <TextSpan data-testid='date'>{endDateString}</TextSpan>
                                    </>
                                )}
                            </TextBlock>
                        )}

                        {layout.includes('LOCATION') && location && (
                            <TextBlock variant='body2'>
                                <LocationCity color='primary' />
                                <TextSpan data-testid='location'>{location}</TextSpan>
                            </TextBlock>
                        )}
                        {layout.includes('VENUE') && venue && (
                            <TextBlock variant='body2'>
                                <LocationIcon color='primary' />
                                <TextSpan data-testid='venue'>{venue}</TextSpan>
                            </TextBlock>
                        )}
                        {layout.includes('SHORT_DESCRIPTION') && shortDescription && (
                            <MyDescription data-testid='description'>{shortDescription}</MyDescription>
                        )}
                        {eventIsPast && <TextBlock variant='body2'>{t('message.event_has_ended')}</TextBlock>}
                        <div style={{ flexGrow: 1 }} />

                        {layout.includes('REGISTRATION_BUTTON') && (
                            <>
                                {['AVAILABLE', 'WAITLIST'].includes(ticketStatus) && (
                                    <RegisterButton variant='contained' disabled={eventIsPast} href={registrationLink}>
                                        {t('form.register')}
                                    </RegisterButton>
                                )}
                                {ticketStatus === 'SOLD_OUT' && (
                                    <SoldOutButton variant='contained' disabled>
                                        {t('form.sold_out')}
                                    </SoldOutButton>
                                )}
                                {ticketStatus === 'NOT_OPEN' && (
                                    <SoldOutButton variant='contained' disabled>
                                        {t('form.not_open')}
                                    </SoldOutButton>
                                )}
                            </>
                        )}
                    </StyledCardContent>
                </MyCard>
            </div>
        );
    }
);

export default GridElement;

import { memo, useState } from 'react';

import { Grid } from '@mui/material';

import Cells from './components/Cells';
import Header from './components/Header';
import Months from './components/Months';
import { ContentCalendar } from './styled';
import { CalendarBlockProps } from './types';

const CalendarBlock = memo(
    ({ events, selectedDate, setSelectedDate, currentDate, setCurrentDate, hideGhostDates }: CalendarBlockProps) => {
        const [calendarByMonth, setCalendarByMonth] = useState(false);

        return (
            <div>
                <Header
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    setSelectedDate={setSelectedDate}
                    calendarByMonth={calendarByMonth}
                    setCalendarByMonth={setCalendarByMonth}
                />
                <ContentCalendar>
                    {calendarByMonth ? (
                        <Grid container>
                            <Months
                                currentDate={currentDate}
                                setCurrentDate={setCurrentDate}
                                setSelectedDate={setSelectedDate}
                                setCalendarByMonth={setCalendarByMonth}
                            />
                        </Grid>
                    ) : (
                        <Cells
                            currentDate={currentDate}
                            setCurrentDate={setCurrentDate}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            events={events}
                            hideGhostDates={hideGhostDates}
                        />
                    )}
                </ContentCalendar>
            </div>
        );
    }
);

export default CalendarBlock;

import { memo, useCallback, useMemo } from 'react';

import { Box, Grid } from '@mui/material';
import {
    startOfMonth,
    endOfMonth,
    startOfWeek,
    endOfWeek,
    addDays,
    format,
    isSameMonth,
    addMonths,
    subMonths,
    isSameDay,
} from 'date-fns';

import Cell from './components/Cell';
import { CellDiv } from './components/Cell/styled';
import DaysOfWeek from './components/DaysOfWeek';
import { CellsProps } from './types';
import { FORMAT_BY } from 'constants/format';
import { filterByMarkColor, filterBySameDay } from 'services/filter';
import { diffDates } from 'services/format';
import useDateFnsLocale from 'services/useDateFnsLocale';

const Cells = memo(
    ({ events, currentDate, selectedDate, setSelectedDate, setCurrentDate, hideGhostDates = false }: CellsProps) => {
        const dateFnsLocale = useDateFnsLocale();
        const monthStart = startOfMonth(currentDate);
        const monthEnd = endOfMonth(monthStart);
        const daysOffset = diffDates(startOfWeek(monthStart, { weekStartsOn: 1 }), monthStart);
        // const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
        // const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });
        const startDate = hideGhostDates ? monthStart : startOfWeek(monthStart, { weekStartsOn: 1 });
        const endDate = hideGhostDates ? monthEnd : endOfWeek(monthEnd, { weekStartsOn: 1 });
        const onDateClick = useCallback(
            (day: Date) => () => {
                if (!isSameMonth(day, monthStart)) {
                    if (day > monthStart) {
                        setCurrentDate(addMonths(currentDate, 1));
                    } else {
                        setCurrentDate(subMonths(currentDate, 1));
                    }
                }
                setSelectedDate(day);
            },
            [monthStart, currentDate]
        );
        const lengthDays = useMemo(() => diffDates(startDate, endDate), [startDate, endDate]);
        const daysOfMonth = useMemo(
            () =>
                [...new Array(lengthDays)].map((current, index) => {
                    const day = addDays(startDate, index);
                    const isSameDayCheck = isSameDay(day, selectedDate);
                    const formatDay = format(day, FORMAT_BY.Day, {
                        locale: dateFnsLocale,
                    });
                    const isBold = isSameDayCheck;
                    const currentDateEvents = filterBySameDay(events, day);
                    const isMark = !!currentDateEvents.length;
                    const markClass = filterByMarkColor(currentDateEvents, isMark);
                    const textColor = !isSameMonth(day, monthStart) ? 'gray' : isSameDayCheck ? 'white' : 'black';
                    const bgColor = isSameDayCheck ? 'primary.main' : '';
                    return { day, formatDay, isBold, isMark, markClass, textColor, bgColor };
                }),
            [startDate, selectedDate, lengthDays, events]
        );
        return (
            <Grid item xs={12}>
                <Box display='flex' flexWrap='wrap' textAlign='center' data-testid='cells'>
                    <DaysOfWeek currentDate={currentDate} />
                    {hideGhostDates && [...new Array(daysOffset)].map((value, index) => <CellDiv key={index} />)}
                    {daysOfMonth.map((item) => (
                        <Cell
                            key={item.day.toString()}
                            formatDay={item.formatDay}
                            isBold={item.isBold}
                            isMark={item.isMark}
                            markClass={item.markClass}
                            textColor={item.textColor}
                            bgColor={item.bgColor}
                            day={item.day}
                            onDateClick={onDateClick}
                        />
                    ))}
                </Box>
            </Grid>
        );
    }
);

export default Cells;

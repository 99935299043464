import { Checkbox, FormControlLabel, Grid, styled } from '@mui/material';

export const HeaderCalendar = styled(Grid)({
    backgroundColor: '#f2f2f2',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    boxSizing: 'border-box',
    textAlign: 'center',
});

export const EmptyCheckbox = styled(Checkbox)({
    display: 'none',
});

export const DateTitle = styled('h2')({
    textDecoration: 'underline',
    textDecorationColor: '#bdbdbd',
});

export const MyFormControlLabel = styled(FormControlLabel)({
    margin: 0,
});

import { memo, useContext, useEffect, useState } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CellMeasurer, CellMeasurerCache, List, WindowScroller } from 'react-virtualized';

import { addLanguageToUrl } from 'services/createUrl';
import ListElement from './components/ListElement';
import { AppContext } from 'context';
import useWindowSize from 'services/useWindowSize';

const defaultHeight = 180;
const cache = new CellMeasurerCache({
    defaultHeight,
    fixedWidth: true,
    minHeight: 40,
});

const ListView = memo(() => {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const {
        events,
        datetimeFormat,
        setEventPage,
        hasMoreEvents,
        layouts: {
            LIST: { eventFields },
        },
        directUrl,
    } = useContext(AppContext);
    const [, setRefreshIndex] = useState(0);

    // Recalculate row heights when window width changes and force rerender
    useWindowSize(() => {
        cache.clearAll();
        setRefreshIndex((index) => index + 1);
    });

    // Recalculate row heights when events change and force rerender
    useEffect(() => {
        cache.clearAll();
        setRefreshIndex((index) => index + 1);
    }, [events]);

    return (
        <div data-testid='listView'>
            <WindowScroller>
                {({ width, height, isScrolling, registerChild, scrollTop }) => (
                    <div ref={registerChild}>
                        <List
                            overscanRowCount={3}
                            autoHeight
                            autoWidth
                            width={width}
                            height={height}
                            isScrolling={isScrolling}
                            scrollTop={scrollTop}
                            rowCount={events.length}
                            deferredMeasurementCache={cache}
                            estimatedRowSize={defaultHeight}
                            rowHeight={cache.rowHeight}
                            rowRenderer={({ key, index, style, parent }) => (
                                <CellMeasurer key={key} cache={cache} columnIndex={0} rowIndex={index} parent={parent}>
                                    {({ registerChild }) => (
                                        <div ref={registerChild} style={style}>
                                            <ListElement
                                                layout={eventFields}
                                                title={events[index].name}
                                                startDate={events[index].starts}
                                                endDate={events[index].ends}
                                                datetimeFormat={datetimeFormat}
                                                location={events[index].location.city}
                                                venue={events[index].location.venue}
                                                ticketStatus={events[index].ticket_status}
                                                registrationLink={
                                                    directUrl
                                                        ? addLanguageToUrl(
                                                              events[index].links.direct_registration,
                                                              language
                                                          )
                                                        : addLanguageToUrl(events[index].links.event_page, language)
                                                }
                                                eventPageLink={addLanguageToUrl(
                                                    events[index].links.event_page,
                                                    language
                                                )}
                                            />
                                        </div>
                                    )}
                                </CellMeasurer>
                            )}
                        />
                    </div>
                )}
            </WindowScroller>
            {hasMoreEvents && <Button onClick={() => setEventPage((page) => page + 1)}>{t('form.load_more')}</Button>}
        </div>
    );
});

export default ListView;

import { styled } from '@mui/material';

export const DayOfWeekBlock = styled('div')({
    maxDidth: '14.2%',
    flex: '1 0 14.2%',
    boxSizing: 'border-box',
});

export const TextBlock = styled('p')(({ theme }) => ({
    fontDeight: 800,
    [theme.breakpoints.down(440)]: {
        fontSize: '70%',
    },
}));

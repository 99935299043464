import { memo } from 'react';

import { Button, Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
    DescriptionSpan,
    DescriptionBlock,
    SlotIcon,
    LocationIcon,
    LocationCity,
    CalendarIcon,
    EventBlock,
    HoverText,
} from './styled';
import { EventElementProps } from './types';
import { useA1 } from 'api';
import { ITicket } from 'interfaces/ITicket';

const EventElement = memo((props: EventElementProps) => {
    const {
        title,
        date,
        startDate,
        endDate,
        layout,
        eventId,
        location,
        venue,
        ticketStatus,
        eventPageLink,
        registrationLink,
        isPast,
    } = props;
    const { t } = useTranslation();
    const { result, loading: isLoadingEvent } = useA1('events/' + eventId, {
        cache: true,
        skip: isPast || (!layout.includes('TICKETS') && !layout.includes('SLOTS')),
    });

    const isParagraph = true;

    let tickets: ITicket[] = result?.event?.tickets ?? [];
    tickets = tickets.filter((ticket) => !ticket.hidden);
    const registrationPossible = ['AVAILABLE', 'WAITLIST'].includes(ticketStatus);

    let ticketSlots = null;
    // Show tickets/ticket slots available only when the status is available or waitlist
    if (registrationPossible && !isLoadingEvent) {
        if (layout.includes('TICKETS')) {
            ticketSlots = tickets.map((ticket) => {
                // Basic text to show for ticket
                let remainingText = ticket.name;

                // Additional ticket availability information
                if (layout.includes('SLOTS')) {
                    if (ticket.amount === null) {
                        remainingText += ': ' + t('form.tickets_remaining');
                    } else {
                        if (ticket.amount - ticket.sold <= 0 && ticket.waitlist) {
                            remainingText += ': ' + t('form.confirm_waitlist');
                        } else {
                            remainingText +=
                                ': ' +
                                t('form.tickets_remaining') +
                                `: ${ticket.amount - ticket.sold}/${ticket.amount}`;
                        }
                    }
                }
                return (
                    !isLoadingEvent && (
                        <DescriptionBlock key={ticket.id}>
                            <SlotIcon color='primary' />
                            <DescriptionSpan data-testid='slots'>
                                {ticket ? remainingText : t('form.no_ticket')}
                            </DescriptionSpan>
                        </DescriptionBlock>
                    )
                );
            });
        } else if (layout.includes('SLOTS')) {
            // Don't list tickets, but show a single availability information from all tickets
            let amount = 0;
            let sold = 0;
            const infiniteTicketExists = tickets.some((ticket) => {
                if (ticket.amount === null) {
                    return true;
                }
                amount += Math.max(0, ticket.amount);
                sold += ticket.sold;
            });
            let remainingText;
            if (infiniteTicketExists) {
                remainingText = t('form.tickets_remaining');
            } else {
                // Status will be WAITLIST when no tickets available and at least one ticket has waitlist on
                if (ticketStatus === 'WAITLIST') {
                    remainingText = t('form.confirm_waitlist');
                } else {
                    remainingText = t('form.tickets_remaining') + `: ${amount - sold}/${amount}`;
                }
            }

            ticketSlots = !isLoadingEvent && (
                <DescriptionBlock>
                    <SlotIcon color='primary' />
                    <DescriptionSpan data-testid='slots'>{remainingText}</DescriptionSpan>
                </DescriptionBlock>
            );
        }
    }

    return (
        <EventBlock>
            {layout.includes('NAME') && (
                <HoverText
                    data-testid='title'
                    variant='body1'
                    paragraph={isParagraph}
                    fontWeight='bold'
                    href={eventPageLink}
                    className='hover-title'
                >
                    {title}
                    {ticketStatus === 'SOLD_OUT' && ' (' + t('form.sold_out') + ')'}
                </HoverText>
            )}
            {(layout.includes('START_DATE') || layout.includes('END_DATE')) && (
                <DescriptionBlock>
                    {layout.includes('START_DATE') && layout.includes('END_DATE') && (
                        <>
                            <CalendarIcon color='primary' />
                            <DescriptionSpan data-testid='date'>{date}</DescriptionSpan>
                        </>
                    )}
                    {layout.includes('START_DATE') && !layout.includes('END_DATE') && (
                        <>
                            <CalendarIcon color='primary' />
                            <DescriptionSpan data-testid='date'>{startDate}</DescriptionSpan>
                        </>
                    )}
                    {!layout.includes('START_DATE') && layout.includes('END_DATE') && (
                        <>
                            <CalendarIcon color='primary' />
                            <DescriptionSpan data-testid='date'>{endDate}</DescriptionSpan>
                        </>
                    )}
                </DescriptionBlock>
            )}
            {layout.includes('LOCATION') && location && (
                <DescriptionBlock>
                    <LocationCity color='primary' />
                    <DescriptionSpan data-testid='location'>{location}</DescriptionSpan>
                </DescriptionBlock>
            )}
            {layout.includes('VENUE') && venue && (
                <DescriptionBlock>
                    <LocationIcon color='primary' />
                    <DescriptionSpan data-testid='location'>{venue}</DescriptionSpan>
                </DescriptionBlock>
            )}

            {isPast && <DescriptionBlock>{t('message.event_has_ended')}</DescriptionBlock>}

            {!isPast && (layout.includes('TICKETS') || layout.includes('SLOTS')) && (
                <Collapse in={!!ticketSlots}>
                    <div>{ticketSlots}</div>
                </Collapse>
            )}
            {layout.includes('REGISTRATION_BUTTON') && (
                <div>
                    {registrationPossible && (
                        <Button
                            variant='contained'
                            href={registrationLink}
                            target='_blank'
                            rel='noopener noreferrer'
                            disabled={isPast}
                        >
                            {t('form.register')}
                        </Button>
                    )}
                    {ticketStatus === 'SOLD_OUT' && (
                        <Button variant='contained' disabled>
                            {t('form.sold_out')}
                        </Button>
                    )}
                    {ticketStatus === 'NOT_OPEN' && (
                        <Button variant='contained' disabled>
                            {t('form.not_open')}
                        </Button>
                    )}
                </div>
            )}
        </EventBlock>
    );
});

export default EventElement;

import { memo, useContext, useEffect, useState } from 'react';

import { Button, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CellMeasurer, CellMeasurerCache, List, WindowScroller } from 'react-virtualized';

import GridRow from './components/GridRow';
import { AppContext } from 'context';
import useWindowSize from 'services/useWindowSize';

const defaultRowHeight = 464;
const cache = new CellMeasurerCache({
    defaultHeight: defaultRowHeight,
    fixedWidth: true,
    minHeight: 40,
});

const GridView = memo(() => {
    const { t } = useTranslation();
    const { events, setEventPage, hasMoreEvents } = useContext(AppContext);
    const [, setRefreshIndex] = useState(0);
    const theme = useTheme();

    // Recalculate row heights when window width changes and force rerender
    const windowSize = useWindowSize(() => {
        cache.clearAll();
        setRefreshIndex((index) => index + 1);
    });

    // Recalculate row heights when events change and force rerender
    useEffect(() => {
        cache.clearAll();
        setRefreshIndex((index) => index + 1);
    }, [events]);

    // Instead of using Grid components breakpoint props, we calculate columns manually for virtualization purposes
    const twoCards = windowSize[0] < theme.breakpoints.values.md && windowSize[0] > theme.breakpoints.values.sm;
    const oneCard = windowSize[0] < theme.breakpoints.values.sm;

    const blockSize = twoCards ? 6 : oneCard ? 12 : 4;
    const columnCount = twoCards ? 2 : oneCard ? 1 : 3;

    return (
        <div data-testid='gridView'>
            <WindowScroller>
                {({ width, height, isScrolling, registerChild, scrollTop }) => (
                    <div ref={registerChild}>
                        <List
                            overscanRowCount={3}
                            width={width}
                            height={height}
                            autoWidth
                            autoHeight
                            isScrolling={isScrolling}
                            scrollTop={scrollTop}
                            rowCount={Math.ceil(events.length / columnCount)}
                            deferredMeasurementCache={cache}
                            estimatedRowSize={defaultRowHeight}
                            rowHeight={cache.rowHeight}
                            rowRenderer={({ key, index, style, parent }) => (
                                <CellMeasurer key={key} cache={cache} columnIndex={0} rowIndex={index} parent={parent}>
                                    {({ registerChild }) => (
                                        <div ref={registerChild} style={style}>
                                            <GridRow
                                                blockSize={blockSize}
                                                columnCount={columnCount}
                                                index={index}
                                                events={events}
                                            />
                                        </div>
                                    )}
                                </CellMeasurer>
                            )}
                        />
                    </div>
                )}
            </WindowScroller>
            {hasMoreEvents && <Button onClick={() => setEventPage((page) => page + 1)}>{t('form.load_more')}</Button>}
        </div>
    );
});

export default GridView;

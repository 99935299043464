export const createCustomThemeUrl = (organizationUri: string, isEmbedded: boolean): string => {
    const baseUrl = isEmbedded
        ? process.env.REACT_APP_EMBED_BASE_URL
        : window.location.origin + process.env.REACT_APP_BASE_URL;
    return `${baseUrl}/static/theme/${organizationUri}/calendar.json`;
};

export const createCustomLocalizationUrl = (organizationUri: string, isEmbedded: boolean): string => {
    const baseUrl = isEmbedded
        ? process.env.REACT_APP_EMBED_BASE_URL
        : window.location.origin + process.env.REACT_APP_BASE_URL;
    return `${baseUrl}/static/localization/${organizationUri}/translation.json`;
};

export const addLanguageToUrl = (url: string | URL, language: string): string => {
    const currentUrl = new URL(url);
    currentUrl.pathname += '/' + language.toUpperCase();
    return currentUrl.href;
};

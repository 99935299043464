import { memo, useContext } from 'react';

import { Typography } from '@mui/material';
import i18next from 'i18next';
import { Markup } from 'interweave'; // https://interweave.dev/docs/#usage
import { useTranslation } from 'react-i18next';

import BottomFilterForm from '../BottomFilterForm';
import ContentViewForm from '../ContentViewForm';
import MainFilterForm from '../MainFilterForm';
import { CONTENT_VIEW } from 'constants/content';
import { AppContext } from 'context';

const Header = memo(() => {
    const { t } = useTranslation();
    const { contentView, translations } = useContext(AppContext);
    const translation = translations.find((translation) => translation.language.toLowerCase() === i18next.language);

    return (
        <div className='calendar-header'>
            <Typography className='calendar-title' variant='title'>
                {translation?.title ?? t('heading.events')}
            </Typography>
            {translation?.description && (
                <Typography
                    variant='description'
                    className='calendar-description'
                    sx={{ mb: 4, wordBreak: 'break-word' }}
                >
                    <Markup content={translation.description} />
                </Typography>
            )}
            <ContentViewForm />
            <MainFilterForm />
            {contentView !== CONTENT_VIEW.Calendar ? <BottomFilterForm /> : null}
        </div>
    );
});

export default Header;

export enum FORMAT_BY {
	FullDateTime = 'dd MMM yyyy HH:mm',
	DayOfWeekWithFullDate = 'cccc, d MMM Y',
	FullDate = 'd MMM Y',
	MonthAndYear = 'LLLL yyyy',
	Week = 'iiiiii',
	Day = 'd',
	Year = 'yyyy',
	Month = 'LLLL',
}

export const STRING_WITHOUT_TAGS = /<\/?[^>]+(>|$)/g;

export function isObject(value: any): boolean {
    return typeof value === 'object' && !Array.isArray(value) && value !== null;
}

export function isBoolean(value: any): boolean {
    if (value === 'true' || Number(value) >= 1 || value === true) {
        return true;
    } else {
        return false;
    }
}

export function isNumber(value: any): boolean {
    if (typeof value === 'number') {
        return true;
    }
    if (!isNaN(+value)) {
        return true;
    }
    return false;
}

/**
 * @description see if value is undefined, null, empty array or object
 */
export function isEmpty(value: any): boolean {
    if (isObject(value)) {
        return Object.keys(value).length === 0;
    } else if (Array.isArray(value)) {
        return value.length === 0;
    } else {
        return !(value || value === '0' || value === 0 || value === false);
    }
}

/**
 * @param {Object} object object containing key/value pairs for query parameters
 * @return {string} query string starting with ? or empty string
 */

import isArray from 'lodash/isArray';

interface QueryObject {
	[key: string]: string | number | boolean | string[];
}

const queryFromObject = (object: QueryObject): string => {
    const params: string[] = [];
    if (object) {
        Object.keys(object).forEach((property) => {
            if (Object.prototype.hasOwnProperty.call(object, property) && object[property] !== null) {
                const value = object[property];
                if (isArray(value)) {
                    params.push(property + '=' + value.map((item) => encodeURIComponent(item)).join(','));
                } else {
                    // params.push(encodeURIComponent(property) + '=' + encodeURIComponent(object[property]));
                    params.push(property + '=' + encodeURIComponent(value));
                }
            }
        });
    }
    if (params.length > 0) {
        return '?' + params.join('&');
    } else {
        return '';
    }
};

export default queryFromObject;

import { isPast } from 'date-fns';
import isSameDay from 'date-fns/isSameDay';

import { IEvent } from 'interfaces/IEvent';

export const filterBySameDay = (events: IEvent[], selectedDate: Date) => {
    return events.filter((event) => isSameDay(new Date(event.starts), selectedDate));
};

export const filterByMarkColor = (events: IEvent[], isMark: boolean) => {
    if (!isMark) {
        return undefined;
    }

    // All events end date is in the past
    const allEventsInPast = !events.some((event) => event.ends === null || !isPast(new Date(event.ends)));
    if (allEventsInPast) {
        return 'past';
    }

    // Some events haven't ended yet
    return events.some((event) => event.ticket_status !== 'SOLD_OUT') ? 'open' : 'closed';
};
